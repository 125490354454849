import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ title, description, ogpImage, lang, meta }) => {
  const { site, ogpDefaultImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            siteUrl
          }
        }
        ogpDefaultImage: file(relativePath: { eq: "images/ogp.jpg" }) {
          publicURL
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const siteTitle = site.siteMetadata.title;
  const targetTitle = (!!title) ? `${title} | ${siteTitle}` : siteTitle;
  const siteUrl = site.siteMetadata.siteUrl;
  const ogpImagePath = siteUrl + ((!!ogpImage) ? ogpImage : ogpDefaultImage.publicURL);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={targetTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: site.siteMetadata.keywords,
        },
        {
          property: `og:title`,
          content: targetTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogpImagePath,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    />
  );
}

Seo.defaultProps = {
  lang: `jp`,
  meta: [],
  description: ``,
  title: null,
  ogpImage: null,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  ogpImage: PropTypes.string,
};

export default Seo;
